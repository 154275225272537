<template>
  <v-card class="battle-item">
    <div class="title-wrap">
      <p class="sub-title">배틀</p>
      <p class="title">{{ battleObject.surveyTitle }}</p>
    </div>
    <v-img src="/assets/common/img_battle_01.svg"></v-img>
    <!-- <v-btn-toggle v-model="battleOption" class="battle-option">
      <v-btn v-for="(item, index) in battleOptionItems" :key="index">{{ item.text }}
        <v-icon v-if="battleOption == index" class="checkbox-selected"></v-icon>
        <v-icon v-else class="checkbox"></v-icon>
      </v-btn>
    </v-btn-toggle> -->
    <v-btn-toggle v-model="battleOption" class="battle-option" :disabled="disabled">
      <v-btn v-for="(option, index) in battleOptionItems" :key="index" @click="selectedItem(option)">
        {{ battleObject['selectExample' + option] }}
        <template v-if="disabled">
          <!-- 선택된 상태 -->
          <v-icon v-if="option == battleObject.answer" class="checkbox"></v-icon>
          <div class="value">{{ battleObject['example' + option + 'Per'] }}%</div>
        </template>
        <template v-else>
          <!-- 선택하는 화면 -->
          <v-icon v-if="battleOption == index" class="checkbox-selected"></v-icon>
          <v-icon v-else class="checkbox"></v-icon>
        </template>
        <template v-if="disabled" #append>
          <!-- 퍼센테이지 여기 넣기 -->
          <div class="value-bar" :style="{ width: battleObject['example' + option + 'Per'] + '%' }"></div>
        </template>
      </v-btn>
    </v-btn-toggle>
    <div class="info">{{ numberFormat(battleObject.surveyJoinCnt) }}명 참여 중</div>
  </v-card>
</template>
<script>
// import { isEmpty } from 'lodash'
import Common from '$$utils/common'
import { isUndefined, isEmpty } from 'lodash'
export default {
  name: 'UiBattleItem', //'BattleItem',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['click-btn'],
  data() {
    return {
      battleObject: {
        surveyId: '',
        surveyTitle: '', // 타이틀
        selectExample1: '', // 선택값 1
        selectExample2: '', // 선택값 2
        example1Per: '', // 결과값 %
        example2Per: '', // 결과값 %
        answer: '', // 선택한 답변
        surveyJoinCnt: 0 // 참여인원
      },
      battleOption: null,
      battleOptionItems: [1, 2],
      sheet01: true
      // disabled: false
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    isSelected() {
      return !isUndefined(this.battleOption)
    }
  },
  watch: {
    item: {
      handler: function (val) {
        this.battleObject = val
      },
      deep: true
    }
  },
  mounted() {
    this.battleObject = this.item
    if (!isEmpty(this.item.answer)) {
      this.battleOption = this.item.answer - 1 // btn option은 0,1로 구성됨
    }
    // console.log('[this.battleObject !!!]', this.battleObject)
  },
  methods: {
    selectedItem(item) {
      // console.log('[battle item!!!]', item, this.battleOption)
      if (this.isAuth) {
        const result = {
          result: 'success',
          value: {
            surveyId: this.isSelected ? this.battleObject.surveyId : '',
            answer: this.isSelected ? item : null
          }
        }
        this.$emit('click-btn', result)
      } else {
        this.battleOption = null
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.moveLogin()
          }
        })
      }
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    moveLogin() {
      this.$router.push({ name: 'CM0100M01' })
    }
  }
}
</script>
