<template>
  <div class="img-upload">
    <div class="img-upload-file">
      <div class="btn-upload">
        <ui-file-upload
          id="imageUpload"
          ref="imageUploadRef"
          v-model="uploadedImages"
          class="btn-upload"
          multiple
          name="imageUpload"
          upload-url="/UPLOAD/file/upload/process"
          :size="5242880"
          extensions="gif, jpg, jpeg, png, bmp, webp"
          :max-files="3"
          :upload-key="filePathId"
          :r-file-list="rfilelist"
          @rfile-input="rfilechanged"
          @upload_complete="uploadCallBack"
          @upload_fail="uploadFailCallBack"
          @input-filter="inputFilter"
          @input-file="imageInput"
          @input-fail="inputFail"
          @update:model-value="updateImg"
          @upload_done="uploadDone"
        >
          <button v-if="addSticker" class="add-sticker">
            <p class="count-text">스티커</p>
          </button>
          <button class="add-photo">
            <p class="count-text">
              <strong>{{ uploadedImages.length + rfilelist.length }}</strong> / {{ imageLimit }}
            </p>
          </button>
        </ui-file-upload>
      </div>

      <!-- 업로드된 이미지 미리보기 -->
      <template v-for="(image, index) in rfilelist" :key="image">
        <div class="upload-img-thumn">
          <img class="img-thumn" :src="'/GW' + image.fileUrl + '&thumbnailSize=100'" alt="Uploaded image" />
          <span class="btn-close" @click="removeImage('LOAD', index)">지우기</span>
        </div>
      </template>
      <template v-for="(image, index) in uploadedImages" :key="index">
        <div class="upload-img-thumn">
          <img class="img-thumn" :src="getThumbImage(image)" alt="Uploaded image" />
          <span class="btn-close" @click="removeImage('UP', index)">지우기</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { UiFileUpload } from '$$components'
import { isEmpty, findIndex } from 'lodash'

export default {
  name: 'UiImgUpload', //'ImgUpload',
  components: { UiFileUpload },
  props: {
    addSticker: {
      type: Boolean,
      default: false
    },
    saveFileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    filePathId: {
      type: String,
      default: ''
    }
  },
  emits: ['img-result', 'img-change', 'upload-fail', 'img-error', 'upload-done'],
  data() {
    return {
      title: '',
      uploadedImages: [],
      rfilelist: [], // 최초 등록된 이미지
      imageLimit: 3, // 최대 이미지 개수
      nickNm: ''
    }
  },
  watch: {
    saveFileList: {
      handler: function () {
        // nVal
        this.rfilelist = this.saveFileList
      },
      deep: true
    }
  },
  mounted() {
    /// this.rfilelist = this.saveFileList // 기존 파일 목록
    // console.log('파일목록!!!', this.rfilelist)
  },
  methods: {
    removeImage(type, index) {
      // console.log('[removeImage!!!!]', type, index)
      if (type === 'UP') {
        this.$refs.imageUploadRef.removeFile(this.uploadedImages[index]) // UiFileUpload > removeFile
        this.uploadedImages.splice(index, 1) // 해당 인덱스 이미지 삭제
      } else if (type === 'LOAD') {
        this.$refs.imageUploadRef.removeFile(this.rfilelist[index]) // rfilelist > removeFile
        // 해당 인덱스 이미지는 삭제되고나서 callback으로 삭제처리함
      }
    },

    uploadCallBack(res) {
      const response = res[0].response
      let result = {}

      if (response.data && response.result.code === 'SUCCESS') {
        // TODO filePathId return 하기
        // this.handleSubmit()
        result.result = 'success'
        result.data = [...this.rfilelist, ...this.uploadedImages]
      } else {
        console.error('업로드 실패 : ', response)
        result.result = 'fail'
        result.data = []
      }
      this.$emit('img-result', result)
    },

    uploadDone() {
      this.$emit('upload-done')
    },

    uploadFailCallBack(res) {
      console.log('[uploadFailCallBack !!!]', res)
      let result = {}
      result.result = 'fail'
      result.data = []
      this.$emit('upload-fail', result)
    },

    inputFilter(newFile, oldFile) {
      // 현재 업로드된 이미지 개수
      const currentUploadedCount = this.uploadedImages.length
      // 현재 등록중인 이미지 개수
      const recentUploadedCount = oldFile.length
      // 업로드된 이미지 총 개수 계산
      const totalUploadedCount = currentUploadedCount + recentUploadedCount + 1

      if (totalUploadedCount > this.imageLimit) {
        this.$emit('img-error', {
          result: 'error',
          type: 'image fail',
          text: '<p class="tit-sub">사진은 최대 3개까지 등록할 수 있어요.</p>' // this.$t('message.STT_0004')
        })
        return
      }
    },

    getThumbImage(fileObj) {
      if (isEmpty(fileObj) || !fileObj.file || fileObj.file.type.substr(0, 6) !== 'image/') {
        return ''
      }

      const URL = window.URL || window.webkitURL
      const blob = URL.createObjectURL(fileObj.file)
      return blob || ''
    },

    imageInput(newFile, inputId) {
      this.uploadedImages.push(...newFile)

      let file = document.querySelector('#' + inputId).files[0]
      let reader = new FileReader()
      reader.addEventListener(
        'load',
        function (e) {
          const image = new Image()
          image.src = e.target.result
        },
        false
      )
      if (file) {
        reader.readAsDataURL(file)
      }
    },

    inputFail(error) {
      console.log('파일 업로드 실패:', error)
      if (error === 'size') {
        this.$emit('img-error', {
          result: 'error',
          type: 'image fail',
          text: this.$t('message.STT_0006')
        })
      } else if (error == 'extensions') {
        this.$emit('img-error', {
          result: 'error',
          type: 'image fail',
          text: this.$t('message.STT_0005')
        })
      }
    },

    upload() {
      this.$refs.imageUploadRef.uploadAll()
    },

    rfilechanged(val) {
      console.log('rfilechanged: ', this.rfilelist, val)
      const idx = findIndex(this.rfilelist, { key: val.removed.key })
      this.rfilelist.splice(idx, 1)

      let result = {
        result: 'success',
        data: [...this.rfilelist, ...this.uploadedImages]
      }
      this.$emit('img-change', result)
    },

    updateImg() {
      let result = {
        result: 'success',
        data: [...this.rfilelist, ...this.uploadedImages]
      }
      this.$emit('img-change', result)
    }
  }
}
</script>
