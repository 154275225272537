<template>
  <v-snackbar v-model="isOpen" :timeout="timeout" @update:model-value="updateSnackbar">
    <span v-if="iconClassName != ''" :class="iconClassName"></span>
    <p v-dompurify-html="text"></p>
  </v-snackbar>
</template>

<script>
import { isNumber, reduce, isEmpty } from 'lodash'

export default {
  name: 'UiSnackbar',
  mixins: [],
  props: {
    isOpenProps: {
      type: Boolean,
      default: false
    },
    description: {
      type: [String, Array],
      default: ''
    },
    closeTime: {
      type: [Number, String],
      default: 2000
    },
    openType: {
      type: String,
      defalt: ''
    },
    iconClassName: {
      type: String,
      default: ''
    }
  },
  emits: ['snack-close'],
  data() {
    return {
      isOpen: false,
      text: '',
      timeout: 0
    }
  },
  computed: {
    // console.log('UICommonModal computed')
  },
  watch: {
    // console.log('UICommonModal watch')
    isOpenProps() {
      // console.log('[this.isOpenProps !!!]', this.isOpenProps)
      if (this.isOpenProps) {
        this.isOpen = this.isOpenProps
      }
      /*
      if (this.isOpen) {
        setTimeout(() => {
          this.$emit('snack-close', this.openType)
        }, this.timeout)
      }
      */
    },
    description() {
      this.setDescription()
    },
    closeTime() {
      this.timeoutSet()
    }
  },
  mounted() {
    this.isOpen = this.isOpenProps
    this.timeout = this.closeTime
    this.setDescription()
    this.timeoutSet()
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    setDescription() {
      if (typeof this.description == 'string') {
        this.text = this.description
      } else {
        if (this.description.length > 0) {
          this.text = reduce(
            this.description,
            (crr, arr, index) => {
              crr = crr + (index > 1 ? '</br>' + arr : arr)
              return crr
            },
            ''
          )
        } else {
          this.text = ''
        }
      }
    },
    timeoutSet() {
      if (!isEmpty(this.closeTime)) {
        if (typeof this.closeTime == 'string') {
          let numberChange = Number(this.closeTime)
          if (isNumber(numberChange)) this.timeout = numberChange
          else this.timeout = 1000
        } else {
          this.timeout = this.closeTime
        }
      }
    },
    updateSnackbar() {
      // 한 화면에서 재사용할 경우 emit되는 메소드에서 this.isOpenProps = false 처리 필수
      this.$emit('snack-close', this.openType)
    }
  }
}
</script>
<style lang="scss"></style>
