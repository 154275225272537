<template>
  <swiper
    class="swiper-profile"
    :modules="modules"
    slides-per-view="auto"
    :space-between="10"
    @init="initSwiper"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="(item, idx) in swiperItems" :key="item.userId">
      <UiChannelProfileItem
        :item="item"
        variant="vertical"
        :show-follower-only="true"
        show-chip-btn
        :show-ranking="showRank"
        :ranking="idx + 1"
        @click="moveActivityLog(item.userId)"
      />
    </swiper-slide>
  </swiper>
</template>

<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import UiChannelProfileItem from './UiChannelProfileItem.vue'

export default {
  name: 'UiSwiperProfile', // 'SwiperProfile'
  components: {
    Swiper,
    SwiperSlide,
    UiChannelProfileItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    showRank: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination]
    }
  },
  data() {
    return {
      swiperItems: [],
      swiperInstance: undefined
    }
  },
  watch: {
    items: {
      handler(newVal) {
        console.log('!!! Updated items:', newVal)
        this.swiperItems = newVal
        if (this.swiperInstance) {
          this.swiperInstance.update()
          this.swiperInstance.slideTo(0)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.swiperItems = this.items
  },
  methods: {
    initSwiper(instance) {
      this.swiperInstance = instance
    },
    moveActivityLog(id) {
      // 활동로그로 이동
      this.$router.push({ path: '/CO/CO0104M00', state: { userId: id } })
    }
  }
}
</script>
