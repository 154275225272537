<template>
  <v-bottom-sheet v-model="isOpenYn" class="ui-bottom">
    <v-card :title="title" class="pa-0 text-left">
      <div class="close-btn" @click="closeSheet()"></div>
      <div class="wrap-scroll">
        <slot></slot>
      </div>
    </v-card>
    <slot name="footer"> </slot>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'UiBottom',
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpenProps: {
      type: Boolean,
      default: false
    }
  },
  emits: ['btm-close'],
  data() {
    return {
      isOpenYn: false
    }
  },
  watch: {
    isOpenProps() {
      this.isOpenYn = this.isOpenProps
    },
    isOpenYn() {
      if (!this.isOpenYn) {
        this.closeSheet()
      }
    }
  },
  mounted() {
    this.isOpenYn = this.isOpenProps
  },
  methods: {
    closeSheet() {
      this.$emit('btm-close')
    }
  }
}
</script>
<style lang="scss"></style>
