<template>
  <swiper
    class="swiper-feed"
    :modules="modules"
    :pagination="pagination"
    :space-between="10"
    @init="initSwiper"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="feed in swiperItem" :key="feed.contentsNo">
      <UiFeedCard :item="feed" :class="color" />
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { UiFeedCard } from '$$bizcomponents'
import { isUndefined } from 'lodash'
export default {
  name: 'UiFeedSwiper', // 'SwiperFeed',
  components: {
    Swiper,
    SwiperSlide,
    UiFeedCard
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination]
    }
  },
  data() {
    return {
      pagination: {
        clickable: true
      },
      swiperItem: [],
      swiperInstance: undefined
    }
  },
  watch: {
    items: {
      handler: function (nVal) {
        console.log('nVal!!1', nVal)
        this.swiperItem = nVal
        if (!isUndefined(this.swiperInstance)) {
          this.swiperInstance.update()
          this.swiperInstance.slideTo(0)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.swiperItem = this.items
    if (this.hidePagination) {
      this.pagination = false
    }
  },
  methods: {
    initSwiper(instance) {
      this.swiperInstance = instance
    }
  }
}
</script>
