<template>
  <v-card class="channel-item" :class="{ banner: banner }">
    <div class="d-flex mb-4">
      <div v-if="category.length > 0 || item.icon" class="chip-group mr-auto">
        <template v-if="category.length > 0">
          <v-chip v-for="cate in category" :key="cate" size="small" :color="getColor(cate)">{{ getCategoryNm(cate) }}</v-chip>
        </template>
        <v-icon v-if="item.icon" class="my-n2" :class="item.icon"></v-icon>
      </div>
      <div v-if="!banner && !myCard" class="my-n2">
        <v-btn v-if="follow" size="small" color="secondary" @click="setFollow('05')">
          <v-icon class="check"></v-icon>
          팔로잉
        </v-btn>
        <v-btn v-else size="small" color="primary" @click="setFollow('04')">
          <v-icon class="add"></v-icon>
          팔로우
        </v-btn>
      </div>
    </div>
    <div v-dompurify-html="item.nickNm" class="channel-name">
      <v-icon class="finfluencer"></v-icon>
    </div>
    <div class="docu-wrap">
      <div v-dompurify-html="item.memo" class="docu"></div>
    </div>
    <div v-if="snsList.length > 0" class="sns-wrap">
      <template v-for="(sns, idx) in snsList" :key="idx">
        <v-btn icon @click="openSnsSite(sns)">
          <v-icon :class="getSnsClass(sns.snsRegisterCd)"></v-icon>
        </v-btn>
      </template>
      <v-btn v-if="!banner" icon class="ml-auto mr-n1" @click="openShare">
        <v-icon class="sns-share"></v-icon>
      </v-btn>
    </div>
    <UiSnackbar :is-open-props="isSnackbarOpen" :description="snackbarText" :open-type="snackbarType" @snack-close="closeSnackbar"></UiSnackbar>
  </v-card>
  <!--
    <UiBottom :is-open-props="sheet01" @btm-close="closeShare">
      <UiSnsList class="mt-6" :items="selectOptionItems" :class="{ 'justify-space-between': selectOptionItems.length > 3 }" />
    </UiBottom>
  -->
</template>
<script>
import { isEmpty, find } from 'lodash'
import { UiSnackbar } from '$$bizcomponents' // UiBottom,
// import UiSnsList from './UiSnsList.vue'

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'

export default {
  name: 'UiFinInfoCard', //'ChannelItem',
  components: {
    /*
    UiSnsList,
    UiBottom,
    */
    UiSnackbar
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    },
    variant: {
      type: String,
      default: ''
    }
  },
  emits: ['modify-follow'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    let codeList = pinia.getCommonCode('ST0021')
    const snsCodeList = reactive(codeList)

    let commonList = pinia.getCommonCode('ST0042')
    const userCardCode = reactive(pinia.getCommonCode('ST0044')) // 사용자 카드 색상 코드

    commonList = [...commonList, ...userCardCode]
    const categoryList = reactive(commonList)

    return {
      snsCodeList,
      categoryList,
      userCardCode
    }
  },
  data() {
    return {
      // category: this.item.category || [],
      banner: false,
      follow: false, // 팔로우여부
      shareYn: false,
      myCard: false,
      // TODO 코드추가하기
      /*
      selectOptionItems: [
        {
          icon: 'copy',
          label: '주소복사'
        },
        {
          icon: 'sns-instagram',
          label: '인스타그램'
        },
        {
          icon: 'sns-kakaotalk',
          label: '카카오톡'
        },
        {
          icon: 'more-h',
          label: '더보기'
        }
        // {
        //   icon: 'sns-facebook',
        //   label: '페이스북'
        // },
        // {
        //   icon: 'sns-x',
        //   label: '엑스'
        // },
      ],
      */
      snsList: [],
      category: [],
      // 스낵바
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    }
  },
  watch: {
    item: {
      handler: function (val) {
        if (!isEmpty(val)) {
          this.snsList = isEmpty(val.snsInfo) ? [] : val.snsInfo.snsList
          this.follow = val.follow
          this.myCard = val.userId === this.$pinia.auth.userData.userId
          // this.category = val.registerDefaultLevel === '03' ? ['999'] : []
          const userCard = find(this.userCardCode, { value: 'FIN' })
          this.category = [userCard.value]
          if (!isEmpty(val.chnlTopicCd)) {
            this.category.splice(0, 0, val.chnlTopicCd)
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.isBanner()
    // this.setSnsList()
  },
  methods: {
    showChip(index) {
      return this.category.includes(index)
    },
    isBanner() {
      if (this.variant == 'banner') {
        this.banner = true
      }
    },
    getSnsClass(snsRegisterCd) {
      const snsObj = find(this.snsCodeList, { value: snsRegisterCd })
      if (isEmpty(snsObj)) {
        return ''
      } else {
        return 'sns-' + snsObj.note + '-gray'
      }
    },
    async setFollow(type) {
      // 팔로우 처리
      if (this.isAuth) {
        const followApi = '/GW/router/STOPLAY/CMN/CmnMemberFollow/save'
        const params = {
          list: [
            {
              cmd: type === '04' ? 'I' : 'U',
              followerId: this.$pinia.auth.userData.userId,
              followingId: this.item.userId,
              followStatusCd: type
            }
          ]
        }

        await this.$http.post(followApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (type === '04') {
              this.follow = true
              this.isSnackbarOpen = true
              this.snackbarType = 'follow'
              this.snackbarText = this.$t('message.STT_0042', { nickNm: this.item.nickNm })
              const emitParam = {
                result: 'success',
                type: '04',
                value: this.item
              }
              this.$emit('modify-follow', emitParam)
            } else if (type === '05') {
              this.follow = false
              this.isSnackbarOpen = true
              this.snackbarType = 'unfollow'
              this.snackbarText = this.$t('message.STT_0043', { nickNm: this.item.nickNm })
              const emitParam = {
                result: 'success',
                type: '05',
                value: this.item
              }
              this.$emit('modify-follow', emitParam)
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.moveLogin()
          }
        })
      }
    },
    openSnsSite(item) {
      const snsUrl = item.snsUrl
      window.open(snsUrl, '_blank')
    },
    getColor(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    },
    closeSnackbar(snackbarType) {
      // 스낵바 종료
      this.isSnackbarOpen = false
      if (snackbarType === 'success') {
        // this.$modalHide(this.modalName, { result: true })
      }
    },
    openShare() {
      this.shareYn = true

      const url = 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/CO/CO0101M01/' + this.item.userId
      const shareResult = this.$nativeApi.showShareList('두부분식', this.$route.meta.title, url)
      if (!shareResult) {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(url)
        }
        this.isSnackbarOpen = true
        this.snackbarType = 'refer'
        this.snackbarText = this.$t('message.STT_0058')
      }
    },
    closeShare() {
      this.shareYn = false
    },
    moveLogin() {
      this.$router.push({ name: 'CM0100M01' })
    }
  }
}
</script>
