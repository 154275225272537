<template>
  <v-bottom-sheet v-model="isOpenYn" class="ui-bottom">
    <v-card :title="title" class="text-left pa-0">
      <div class="close-btn" @click="closeSheet()"></div>
      <div class="wrap-scroll in">
        <div class="wrap-introduce">
          <div class="comtent-btn">
            <button v-for="(item, index) in dataList" :key="index" :class="getClass(item, index)" @click="itemClick(item, index)">
              <span>{{ selectedNumber(item) }}</span>
              {{ item.value }}
            </button>
          </div>
          <ul v-if="desc.length > 0" class="comtent-self-text mb-0">
            <li v-for="(item, index) in desc" :key="index">
              {{ item }}
            </li>
          </ul>

        </div>
      </div>
    </v-card>
    <div class="wrap-c-btn" style="margin-top: 20px;">
      <button class="cancel c-btn c-btn-gray bg-light rate-1" :disabled="selectedList.length === 0" @click="resetSelect()">선택해제</button>
      <button class="save c-btn c-btn-purple bg-light rate-2" :disabled="selectedList.length === 0" @click="save()">적용하기</button>
    </div>
  </v-bottom-sheet>
</template>

<script>
import { isUndefined, find, findIndex, forEach, reduce } from 'lodash'

export default {
  name: 'UiBottomList',
  mixins: [],
  props: {
    title: {
      type: String,
      default: '자기소개 문구'
    },
    list: {
      type: Array,
      default: () => []
    },
    isOpenProps: {
      type: Boolean,
      default: false
    },
    description: {
      type: [String, Array],
      default: ''
    }
    /*
    alertCnt: {
      type: Number,
      default: 0
    },
    alertText: {
      type: String,
      default: ''
    }
    */
  },
  emits: ['btm-close', 'btm-list-save', 'btm-props-change'],
  data() {
    return {
      dataList: [],
      selectedList: [],
      isOpenYn: false,
      desc: []
    }
  },
  computed: {
    // console.log('UICommonModal computed')
  },
  watch: {
    // console.log('UICommonModal watch')
    list: {
      handler: function () {
        this.dataList = [...this.list]
      },
      deep: true
    },
    isOpenProps: {
      handler: function () {
        this.isOpenYn = this.isOpenProps
        this.resetSelect()
      },
      deep: true
    },
    isOpenYn() {
      if (this.isOpenProps && !this.isOpenYn) this.changeOpenPros()
    }
    /*
    alertCnt() {
      if (!isEmpty(this.alertText)) this.openAlert()
    }
    */
  },
  mounted() {
    this.dataList = [...this.list]
    this.isOpenYn = this.isOpenProps
    if (typeof this.desc === 'object') {
      this.desc = [...this.description]
    } else {
      this.desc.push(this.description)
    }
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    getClass(obj) {
      if (isUndefined(obj.clickYn) || !obj.clickYn) return ''
      else return 'on'
    },
    itemClick(obj) {
      // 목록 click event
      if (isUndefined(obj.clickYn)) {
        obj.clickYn = true
        this.selectedList.push(obj.key)
      } else {
        obj.clickYn = !obj.clickYn
        if (obj.clickYn) this.selectedList.push(obj.key)
        else {
          let selectFindIndex = findIndex(this.selectedList, item => {
            if (item == obj.key) return item
          })
          this.selectedList.splice(selectFindIndex, 1)
        }
      }
    },
    selectedNumber(obj) {
      // 클릭 시 순번 노출
      let selectFindIndex = findIndex(this.selectedList, item => {
        if (item == obj.key) return item
      })
      if (selectFindIndex > -1) return selectFindIndex + 1
      else return ''
    },
    resetSelect() {
      // 선택해제 클릭
      this.selectedList.splice(0, this.selectedList.length)
      forEach(this.dataList, obj => {
        obj.clickYn = false
      })
    },
    save() {
      // 적용하기 클릭
      // console.log('[memo save init!!!]', this.memo, this.memo.length)
      const returnList = reduce(
        this.selectedList,
        (crr, arr) => {
          const findData = find(this.dataList, { key: arr })
          crr.push(findData)
          return crr
        },
        []
      )
      this.$emit('btm-list-save', returnList)
    },
    closeSheet() {
      this.$emit('btm-close')
    },
    changeOpenPros() {
      this.$emit('btm-props-change')
    }
    /*
    openAlert() {
      this.$alert('알림', this.alertText)
    }
    */
  }
}
</script>
<style lang="scss"></style>
