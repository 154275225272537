<template>
  <v-card class="feed-item view">
    <div class="d-flex mb-4">
      <div v-if="category.length > 0" class="chip-group mr-auto">
        <template v-for="cate in category" :key="cate">
          <v-chip size="small" :color="getColor(cate)">{{ getCategoryNm(cate) }}</v-chip>
        </template>
      </div>
      <div v-if="!adminYn && finfleYn && !isMine" class="my-n2">
        <v-btn v-if="feedObj.follow" size="small" color="secondary" @click="setFollow('05')">
          <v-icon class="check"></v-icon>
          팔로잉
        </v-btn>
        <v-btn v-else size="small" color="primary" @click="setFollow('04')">
          <v-icon class="add"></v-icon>
          팔로우
        </v-btn>
      </div>
    </div>

    <div class="user-wrap" @click="moveActivityLog(feedObj.regId)">
      <div class="user-img-wrap">
        <UiUserImg v-if="!adminYn" :data="feedObj" :img-class="'user-img'" :img-type="'finfle'"></UiUserImg>
        <v-img v-else class="user-img" :src="imgUrl"></v-img>
        <v-icon v-if="adminYn" class="badge-admin"></v-icon>
      </div>
      <div class="user-info">
        <div class="user-name">
          {{ name }}
          <span class="user-time">
            {{ feedObj.regDtMark }}
          </span>
        </div>
        <div v-if="feedObj.boardType === 'FIN' && feedObj.baseId" class="user-type">
          {{ feedObj.baseNickNm + ' 채널' }}
        </div>
      </div>
    </div>

    <div class="docu-wrap">
      <div v-dompurify-html="feedObj.contents" class="docu"></div>
      <template v-for="(imgObj, idx) in imgList" :key="idx">
        <v-img
          class="docu-img"
          :src="getFileSrc('/GW' + imgObj.fileUrl)"
          cover
          eager
          @click="clickImg('/GW' + imgObj.fileUrl)"
          @load="loadingDone()"
        ></v-img>
        <!-- + '&thumbnailSize=300' -->
      </template>

      <div v-if="feedObj.movieUrl" class="video-wrap">
        <UiYoutube :url="feedObj.movieUrl" :autoplay="'0'" width="100%" height="100%"></UiYoutube>
      </div>

      <div v-if="hashtagList" class="chip-wrap">
        <v-chip v-for="hashtag in hashtagList" :key="hashtag" color="gray-darken-3" variant="elevated" size="large" @click="searchPost(hashtag)">
          #{{ hashtag }}
        </v-chip>
      </div>
      <div v-if="feedObj.mentionNickNm" class="mention-wrap">
        <p @click="moveActivityLog(feedObj.mentionId)">@{{ feedObj.mentionNickNm }}</p>
      </div>
    </div>
    <slot></slot>
    <!-- 20241113 추가 st -->
    <v-btn v-if="pdfList.length > 0" class="btn-file-download mt-6" color="gray-darken-1">
      <template v-for="(pdf, idx) in pdfList" :key="idx">
        <v-icon class="file-gray"></v-icon>
        <p>{{ pdf.upldFileNm }}</p>
        <v-icon class="download-gray" @click="downloadPdf(pdf)"></v-icon>
      </template>
    </v-btn>
    <div v-if="feedObj.link1" class="btn-wrap mt-5">
      <v-btn color="gray" variant="outlined" @click="moveLinkUrl(feedObj.link1)">
        <v-icon class="link-gray"></v-icon>
        관련 내용 더보기
      </v-btn>
    </div>
    <!-- 20241113 추가 ed -->
    <div class="action-wrap d-flex">
      <div>
        <p class="view">조회 {{ numberFormat(feedObj.searchCount, 0) }}</p>
        <div class="d-flex">
          <v-checkbox
            v-model="likeCheckYn"
            class="like"
            :label="numberFormat(feedObj.likeCount, 0)"
            hide-details="auto"
            @change="modifyLike('LIKE')"
          ></v-checkbox>
          <v-checkbox
            v-if="dislikeYn"
            v-model="hateCheckYn"
            class="dislike"
            :label="numberFormat(feedObj.hateCount, 0)"
            hide-details="auto"
            @change="modifyLike('HATE')"
          ></v-checkbox>

          <v-btn variant="text" size="small" class="text-light-4">
            <v-icon class="comment"></v-icon>
            {{ numberFormat(feedObj.commentCount, 0) }}
          </v-btn>
        </div>
      </div>
      <v-btn class="ml-auto" icon @click="openPostEdit = true">
        <v-icon class="more">버튼 더보기</v-icon>
      </v-btn>
    </div>
  </v-card>
  <UiBottom :is-open-props="openPostEdit" @btm-close="openPostEdit = false">
    <UiSelectList :items="postEditOptions" class="mt-n3" @selected-item="selectedItem" />
  </UiBottom>
  <UiBottom title="이 글에 어떤 문제가 있나요?" :is-open-props="openNotify" @btm-close="openNotify = false">
    <div class="checkbox-list">
      <ul>
        <li v-for="notify in notifyList" :key="notify.value">
          <v-checkbox v-model="selectedReport" :label="notify.text" hide-details="auto" :value="notify.value"></v-checkbox>
        </li>
      </ul>
    </div>
    <template #footer>
      <div class="btn-wrap">
        <v-btn color="primary" @click="confirmReport">확인</v-btn>
      </div>
    </template>
  </UiBottom>
  <UiSnackbar :is-open-props="isSnackbarOpen" :description="snackbarText" :open-type="snackbarType" @snack-close="closeSnackbar"></UiSnackbar>
</template>
<script>
import { UiYoutube } from '$$components'
import {
  // SelectOption,
  UiBottom,
  UiSnackbar,
  UiUserImg
} from '$$bizcomponents'
import UiSelectList from './UiSelectList.vue'
import { isEmpty, find, reduce, isUndefined, map, filter } from 'lodash'

// import { NativeInterfacePlugin } from '$$plugins/NativeInterfacePlugin'
import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'
import Common from '$$utils/common'
export default {
  name: 'UiFeedDetail', //'FeedItemView',
  components: {
    UiSelectList,
    UiBottom,
    UiSnackbar,
    UiUserImg,
    UiYoutube
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    showDislike: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click-img', 'img-loading-done'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()

    let finfleList = pinia.getCommonCode('ST0042') // 핀플게시판 코드
    let openTalkList = pinia.getCommonCode('ST0031') // 오픈톡 코드
    const userCardCode = reactive(pinia.getCommonCode('ST0044')) // 사용자 카드 색상 코드

    finfleList = [...finfleList, ...userCardCode]
    const categoryList1 = reactive(finfleList)

    openTalkList = [...openTalkList, ...userCardCode]
    const categoryList2 = reactive(openTalkList)

    let editList = pinia.getCommonCode('ST0049') // 게시글 처리 목록 코드
    map(editList, obj => {
      obj.icon = obj.value
    })
    const postEditList = reactive(editList) // 게시글 변경 색상 코드

    const notifyList = pinia.getCommonCode('ST0039') // 신고 사유 목록 코드
    return {
      categoryList1,
      categoryList2,
      userCardCode,
      postEditList,
      notifyList
    }
  },
  data() {
    return {
      // category: this.item.category || [],
      adminYn: false,
      follow: false,
      finfleYn: false,
      postEditOptions: [],

      openPostEdit: false,
      openNotify: false,

      feedObj: this.item,

      imgList: [],
      imgLodingCnt: 0,
      pdfList: [],
      category: [],
      dislikeYn: false,
      categoryList: [],
      hashtagList: [],

      likeCheckYn: false,
      hateCheckYn: false,

      snsList: [],
      myCard: false,
      selectedReport: '', // 신고 선택사항
      // 스낵바
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },
  computed: {
    imgUrl() {
      if (this.adminYn) {
        return '/assets/common/character_64_admin.svg'
      } else {
        return ''
      }
    },
    name() {
      if (this.adminYn) {
        return '두부분식매니저'
      } else {
        return this.feedObj.nickNm
      }
    },
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    isMine() {
      if (this.isAuth) return this.feedObj.regId === this.$pinia.auth.userData.userId
      else return false
    },
    userId() {
      return this.$pinia.auth.userData.userId
    }
  },
  watch: {
    item: {
      handler: function (val) {
        if (!isEmpty(val)) {
          this.feedObj = val
          this.dataSetting()
        }
      },
      deep: true
    }
  },
  mounted() {
    /*
    this.feedObj = this.item
    if (!isEmpty(this.feedObj)) {
      this.dataSetting()
    }
    */
  },
  methods: {
    dataSetting() {
      const val = this.feedObj
      this.snsList = isEmpty(val.snsInfo) ? [] : val.snsInfo.snsList
      // this.follow = val.follow // 팔로우여부
      this.adminYn = isEmpty(val.nickNm) // 관리자여부
      this.myCard = val.regId === val.userId // 카드노출 여부
      this.finfleYn = val.registerDefaultLevel === 3 // 핀플루언서 여부
      this.settingHashtag() // 해시태그 셋팅

      // 파일 셋팅
      const totalList = this.feedObj.fileUploadClientList
      this.imgList = filter(totalList, obj => {
        return obj.fileExtn != 'pdf'
      })
      this.pdfList = filter(totalList, obj => {
        return obj.fileExtn == 'pdf'
      })
      // 사용자 카드 셋팅
      this.category = []
      if (val.registerDefaultLevel === 3) {
        const userCard = find(this.userCardCode, { value: 'FIN' })
        this.category.push(userCard.value)
      } else if (this.adminYn) {
        const userCard = find(this.userCardCode, { value: 'ADMIN' })
        this.category.push(userCard.value)
      }

      // 주제 카드 셋팅
      if (val.boardType !== 'FIN') {
        // 오픈톡 주제
        this.dislikeYn = true
        this.categoryList = this.categoryList2
        isEmpty(val.postSubjectCd) ? false : this.category.splice(0, 0, val.postSubjectCd)
      } else {
        // 핀플채널 주제
        this.dislikeYn = false
        this.categoryList = this.categoryList1
        isEmpty(val.baseChnlTopicCd) ? false : this.category.splice(0, 0, val.baseChnlTopicCd)
      }

      // 좋아요/싫어요
      this.likeCheckYn = val.likeYn === 'Y'
      this.hateCheckYn = val.hateYn === 'Y'

      // 게시물 edit 옵션
      let findType = 'none'
      if (val.regId === this.userId) {
        findType = 'own'
      }
      this.postEditOptions = filter(this.postEditList, obj => {
        if (obj.note === findType) return obj
      })
      this.$forceUpdate()
      if (this.imgList.length === 0) {
        // 댓글 이동 emit 보내기
        this.$emit('img-loading-done')
      }
    },
    showChip(index) {
      return this.category.includes(index)
    },
    selectedItem(item) {
      this.openPostEdit = false
      if (item.value == 'delete') {
        console.log('delete')
        this.$confirm('알림', this.$t('message.STE_0018'), '취소', '삭제하기', undefined, '', 'out').then(res => {
          console.log('삭제처리 !!!!', res)
          if (res === 'secondResult') {
            this.deletePost()
          }
        })
      } else if (item.value == 'modify') {
        const movepage = this.feedObj.boardType === 'FIN' ? 'CO0101M03' : 'CO0102M03'
        this.$router.replace({ name: movepage, state: { contentsNo: this.feedObj.contentsNo } })
        // console.log('수정!')
      } else if (item.value == 'notify') {
        if (this.isAuth) this.openNotify = true
        else this.openLoginModal()
      } else if (item.value == 'share') {
        if (this.isAuth) {
          const url = 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr' + this.$route.fullPath
          const shareResult = this.$nativeApi.showShareList('두부분식', this.$route.meta.description, url)
          if (!shareResult) {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(url)
            }
            this.isSnackbarOpen = true
            this.snackbarType = 'refer'
            this.snackbarText = this.$t('message.STT_0058')
          }
        } else {
          this.openLoginModal()
        }
      }
    },
    async setFollow(type) {
      // 팔로우 처리
      if (this.isAuth) {
        const followApi = '/GW/router/STOPLAY/CMN/CmnMemberFollow/save'
        const params = {
          list: [
            {
              cmd: type === '04' ? 'I' : 'U',
              followerId: this.userId,
              followingId: this.feedObj.regId,
              followStatusCd: type
            }
          ]
        }

        await this.$http.post(followApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (type === '04') {
              this.feedObj.follow = true
              // this.follow = true
              this.isSnackbarOpen = true
              this.snackbarType = 'follow'
              this.snackbarText = this.$t('message.STT_0042', { nickNm: this.feedObj.nickNm })
            } else if (type === '05') {
              this.feedObj.follow = false
              // this.follow = false
              this.isSnackbarOpen = true
              this.snackbarType = 'unfollow'
              this.snackbarText = this.$t('message.STT_0043', { nickNm: this.feedObj.nickNm })
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this.openLoginModal()
      }
    },
    openLoginModal() {
      this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
        if (result == 'secondResult') {
          this.moveLogin()
        }
      })
    },
    moveLogin() {
      this.$router.push({ name: 'CM0100M01' })
    },
    closeSnackbar(snackbarType) {
      // 스낵바 종료
      this.isSnackbarOpen = false
      if (snackbarType === 'success') {
        // this.$modalHide(this.modalName, { result: true })
      }
    },
    openSnsSite(item) {
      const snsUrl = item.snsUrl
      window.open(snsUrl, '_blank')
    },
    getColor(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    },
    settingHashtag() {
      // 해시태그 셋팅
      const hashtagArr = isEmpty(this.feedObj.hashtag) ? [] : this.feedObj.hashtag.split(',')
      this.hashtagList = reduce(
        hashtagArr,
        (crr, arr) => {
          const returnValue = arr.replaceAll('#', '')
          crr.push(returnValue)
          return crr
        },
        []
      )
      console.log('hashtagList!!!', this.hashtagList)
    },
    clickImg(src) {
      const imgUrl = this.getFileSrc(src)
      this.$emit('click-img', { result: 'success', data: { imgUrl: imgUrl } })
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    modifyLike(type) {
      // 좋아요/싫어요 처리
      // console.log('modifyLike!!! init', type)
      const typeLower = type.toLowerCase()
      if (this.isAuth) {
        // const cmd = this.feedObj[typeLower + 'Yn'] === 'Y' ? 'D' : 'I'
        const params = {
          list: [
            {
              cmd: 'I',
              tableId: this.feedObj.tableId,
              boardType: this.feedObj.boardType,
              contentsNo: this.feedObj.contentsNo,
              userId: this.userId,
              actionTypeCd: type, // 활동구분코드 LIKE HATE
              useYn: 'Y',
              writeTypeCd: 'POST'
            }
          ]
        }
        const modiLikeApi = '/GW/router/STOPLAY/CMN/CmnMemberPostAction/save'
        this.$http.post(modiLikeApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (this.feedObj[typeLower + 'Yn'] === 'Y') {
              this.feedObj[typeLower + 'Yn'] = 'N'
              this.feedObj[typeLower + 'Count']--
            } else {
              this.feedObj[typeLower + 'Yn'] = 'Y'
              this.feedObj[typeLower + 'Count']++
              // 좋아요/싫어요 하나만 가능하도록 처리
              if (type === 'LIKE' && this.feedObj.hateYn == 'Y') {
                this.feedObj.hateYn = 'N'
                this.feedObj.hateCount--
                this.hateCheckYn = false
              } else if (type === 'HATE' && this.feedObj.likeYn == 'Y') {
                this.feedObj.likeYn = 'N'
                this.feedObj.likeCount--
                this.likeCheckYn = false
              }
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this[typeLower + 'CheckYn'] = false
        this.openLoginModal()
      }
    },
    deletePost() {
      const boardApi = '/GW/router/STOPLAY/BRD/BrdPost/save'
      const _params = {
        list: [
          {
            cmd: 'D',
            tableId: this.feedObj.tableId,
            boardType: this.feedObj.boardType,
            contentsNo: this.feedObj.contentsNo,
            baseId: this.feedObj.baseId
          }
        ]
      }
      this.$http.post(boardApi, _params).then(async res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          console.log('이동하기!!!')
          if (this.feedObj.boardType === 'FIN') {
            this.$router.replace({ path: '/CO/CO0101M00' })
          } else {
            this.$router.replace({ path: '/CO/CO0102M01' })
          }
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    },
    confirmReport() {
      console.log('신고하기!!!', this.selectedReport)
      const reportObj = find(this.notifyList, { value: this.selectedReport })

      const reportApi = '/GW/router/STOPLAY/BRD/BrdReport/save'
      const _params = {
        list: [
          {
            cmd: 'I',
            tableId: this.feedObj.tableId,
            boardType: this.feedObj.boardType,
            contentsNo: this.feedObj.contentsNo,
            reportTypeCd: 'POST', // 신고유형 POST	게시글 / COMMENT	코멘트 / USER	사용자
            reportRsnCd: reportObj.value, // 신고사유
            reportRsnContents: reportObj.text, // 신고사유내용
            reportStatusCd: '01', // 신고상태
            reporterId: this.userId // 신고자
          }
        ]
      }
      this.$http.post(reportApi, _params).then(async res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          this.openNotify = false
          this.selectedReport = ''
          this.isSnackbarOpen = true
          this.snackbarType = 'report'
          this.snackbarText = this.$t('message.STT_0047')
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    },
    searchPost(srchTxt) {
      if (this.feedObj.boardType === 'FIN') {
        this.$pinia.com.SITE_TYPE_UPDATE('FIN')
      } else {
        this.$pinia.com.SITE_TYPE_UPDATE('OPEN')
      }
      this.$pinia.com.SEARCH_TEXT_UPDATE(srchTxt)
      this.$router.push({ path: '/CO/CO0101M05' })
    },
    moveActivityLog(userId) {
      // 활동로그로 이동
      if (!this.adminYn) {
        this.$router.push({ path: '/CO/CO0104M00', state: { userId: userId } })
      }
    },
    getFileSrc(fileSrc) {
      // const time = new Date().getTime()  + '&time=' + time
      return fileSrc
    },
    moveLinkUrl(url) {
      window.open(url, '_blank')
    },
    async downloadPdf(pdf) {
      //console.log('downloadPdf!!!', src, 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/UPLOAD' + src)
      const param = {
        filePathId: pdf.filePathId,
        filePathSeqno: pdf.filePathSeqno
      }
      const blobUrl = await this.$novaCom.pdfDown(param)
      if (blobUrl) {
        window.open(blobUrl, '_blank')
      }
      // window.open('https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/UPLOAD' + src, '_blank')
    },
    loadingDone() {
      this.imgLodingCnt++
      if (this.imgList.length === this.imgLodingCnt) {
        this.$emit('img-loading-done')
      }
    }
  }
}
</script>
