<template>
  <swiper
    class="swiper-thumbnail"
    :modules="modules"
    slides-per-view="auto"
    :space-between="16"
    @init="initSwiper"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="video in videoList" :key="video.contentsNo">
      <v-img :src="getVideoUrl(video.fileUploadClientList)" @click="openVideo(video)"></v-img>
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { UiMediaPlayer } from '$$components'
import { isEmpty } from 'lodash'
export default {
  name: 'UiSwiperThumbnail', //'SwiperThumbnail',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination]
    }
  },
  data() {
    return {
      videoList: [],
      swiperInstance: undefined
    }
  },
  watch: {
    items(nVal) {
      this.videoList = nVal
      if (this.swiperInstance) {
        this.swiperInstance.update()
        this.swiperInstance.slideTo(0)
      }
    }
  },
  mounted() {
    this.videoList = this.item
  },
  methods: {
    initSwiper(instance) {
      this.swiperInstance = instance
    },
    getVideoUrl(fileList) {
      if (!isEmpty(fileList)) {
        return '/GW' + fileList[0].fileUrl
      } else {
        return ''
      }
    },
    openVideo(video) {
      let params = { modalName: 'UiMediaPlayer', activeBtnClose: true, isTitle: false, headerClass: 'position-fixed', video: video }
      this.$modalOpen(undefined, UiMediaPlayer, { params: params })
    }
  }
}
</script>
