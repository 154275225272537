<template>
  <v-card class="banner-item" :class="color">
    <div class="d-flex align-center">
      <div>
        <div v-dompurify-html="item.title" class="title"></div>
        <div v-if="item.docu" class="docu">{{ item.docu }}</div>
        <v-btn v-for="(button, i) in buttons" :key="i" size="small" variant="text" class="text-light">
          {{ button }}
          <v-icon class="arr-right" />
        </v-btn>
      </div>
      <v-img :src="imgUrl"></v-img>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'UiBannerCard', // 'BannerItem',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    color: {
      type: String,
      default: 'purple'
    }
  },
  data() {
    return {
      imgUrl: this.item.imgUrl || '/assets/common/character_118_vip.svg',
      buttons: []
    }
  },
  mounted() {
    this.buttons = this.item.buttons
  }
}
</script>
