<template>
  <v-expansion-panels v-model="value" variant="accordion" class="expansion-info">
    <v-expansion-panel v-for="(item, index) in items" :key="index">
      <v-expansion-panel-title>
        {{ item.title }}
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <div class="docu">
          {{ item.docu }}
        </div>
        <v-img :src="item.imgUrl"></v-img>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'UiExpansionInfo', // ExpansionInfo
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      value: 0
    }
  },
  mounted() {}
}
</script>
