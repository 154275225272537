<template>
  <swiper
    class="swiper-docu"
    :modules="modules"
    :pagination="{
      clickable: true
    }"
    slides-per-view="auto"
    :space-between="8"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="item in items" :key="item.contentsNo" @click="selectedSwiper(item.contentsNo, item.boardType, item.tableId)">
      <p class="title">{{ item.contents }}</p>
      <p class="time">{{ item.regDtMark }}</p>
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  name: 'UiItemSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: Array
  }, // 'SwiperDocu'
  emits: ['selected-swiper'],
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination]
    }
  },
  methods: {
    selectedSwiper(id, board, table) {
      this.$emit('selected-swiper', id, board, table)
    }
  }
}
</script>
