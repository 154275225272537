<template>
  <v-btn-toggle class="select-option" :class="[variant, direction]">
    <v-btn v-for="item in items" :key="item.value" @click="selectItem(item)">
      <v-icon :class="item.icon"></v-icon>
      {{ item.text }}
    </v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  name: 'UiSelectList',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    },
    variant: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: ''
    }
  }, // 'SelectOption',
  emits: ['selected-item'],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    selectItem(item) {
      this.$emit('selected-item', item)
    }
  }
}
</script>
