<template>
  <ul class="opentalk-list">
    <template v-for="openTalk in openTalkList" :key="openTalk.contentsNo">
      <li @click="moveDetail(openTalk)">
        <p class="title">{{ openTalk.contents }}</p>
        <ul class="user-info">
          <li>{{ getCategoryNm(openTalk.postSubjectCd) }}</li>
          <li>{{ getNickNm(openTalk.nickNm) }}</li>
        </ul>
      </li>
    </template>
  </ul>
</template>
<script>
import { isEmpty, find } from 'lodash'
export default {
  name: 'UiSimpleOpentalkList', // 'OpentalkList',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    }
  },
  data() {
    return {
      openTalkList: [],
      categoryList: []
    }
  },
  computed: {},
  watch: {
    items: {
      handler: function (nValue) {
        this.openTalkList = nValue
      },
      deep: true
    }
  },
  mounted() {
    this.categoryList = this.$pinia.auth.getCommonCode('ST0031') // 오픈톡 코드
  },
  methods: {
    getNickNm(nickNm) {
      if (isEmpty(nickNm)) {
        return '두부분식매니저'
      } else {
        return nickNm
      }
    },
    getCategoryNm(categoryCd) {
      const findCate = find(this.categoryList, { value: categoryCd })
      if (isEmpty(findCate)) {
        return ''
      } else {
        return findCate.text
      }
    },
    moveDetail(item) {
      this.$router.push({ path: '/CO/CO0102M02', query: { contentsNo: item.contentsNo } })
    }
  }
}
</script>
