<template>
  <div class="main-event-wrap" :style="'padding-top:' + (items.length - 1) * topHeight + 'px'" :class="{ open: openCard }">
    <v-btn size="x-small" @click="clickOpen">
      <v-icon :class="[openCard ? 'unexpand' : 'expand']"></v-icon>
      {{ openCard ? '접기' : '펼쳐보기' }}
    </v-btn>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="event-item"
      :class="[item.class, item.active]"
      :style="'top:' + topHeight * index * -1 + 'px;' + 'z-index:' + (items.length - index)"
      @click="clickItem(index)"
      @animationend="removeAction(item)"
    >
      <p class="title">
        {{ item.title }}
      </p>
      <p v-dompurify-html="item.docu" class="docu"></p>
      <v-img :src="item.imgUrl"></v-img>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiMainEvent', // 'MainEvent',
  data() {
    return {
      topHeight: 60,
      items: [
        {
          class: 'nth-0',
          active: '',
          title: '두부분식 랭킹전!',
          docu: '친구 추천하고 포인트 쌓음 정도를 <br/>굉장히 중요시 여기거덩요~',
          imgUrl: '/assets/events/home_event_01.svg',
          moveUrl: 'PR0101M01'
        },
        {
          class: 'nth-1',
          active: '',
          title: '나야~ 리뷰이벤트',
          docu: '근데 이제 <br/>아이폰16을 곁들인.',
          imgUrl: '/assets/events/home_event_02.svg',
          moveUrl: 'PR0104M01'
        },
        {
          class: 'nth-2',
          active: '',
          title: '나야~ 출석체크',
          docu: '멘탈 Check! <br/>두부포인트100모 Check!',
          imgUrl: '/assets/events/home_event_03.svg',
          moveUrl: 'PR0102M01'
        }
      ],
      timer: null,
      openCard: false
    }
  },
  methods: {
    clickOpen() {
      this.openCard = !this.openCard
    },
    clickItem(index) {
      if (!this.openCard) {
        this.items.forEach(item => {
          item.active = ''
        })
        const item = this.items[index]
        clearTimeout(this.timer)
        if (index > 0) {
          item.active = 'down'
          this.timer = setTimeout(() => {
            this.items.splice(index, 1)
            this.items.unshift(item)
            item.active = 'up'
          }, 200)
        } else {
          this.timer = setTimeout(() => {
            item.active = 'click'
            this.$router.push({ name: this.items[index].moveUrl })
          }, 100)
        }
      } else {
        this.$router.push({ name: this.items[index].moveUrl })
      }
    },
    removeAction(item) {
      if (item.active) {
        item.active = ''
      }
    }
  }
}
</script>
