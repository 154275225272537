<template>
  <div class="list-top-wrap">
    <v-btn variant="text" size="small" class="text-light-6" @click="open()">
      <v-icon class="sort-gray mr-2"></v-icon>
      {{ headerTitle }}
    </v-btn>
    <v-btn icon class="ml-auto" @click="searchPost">
      <v-icon class="search-gray"></v-icon>
    </v-btn>
  </div>
  <UiBottom title="정렬 순서를 선택하세요" :is-open-props="openYn" @btm-close="close()">
    <UiSelectList :items="searchTypeList" @selected-item="selectedItem" />
  </UiBottom>
</template>
<script>
import { isEmpty, map, find } from 'lodash'
import { UiBottom } from '$$bizcomponents'
import UiSelectList from './UiSelectList.vue'

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'
import { lowerCase } from 'lodash'

export default {
  name: 'UiSearchBar', //'ListTop',
  components: {
    UiSelectList,
    UiBottom
  },
  props: {
    srchType: {
      type: String,
      default: ''
    }
  },
  emits: ['selected-item'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    let codeList = pinia.getCommonCode('ST0043')
    map(codeList, (obj, idx) => {
      obj.icon = lowerCase(obj.value)
      obj.header = idx === 0 ? '최신순' : obj.text.substring(0, 3) + ' 순'
    })

    const searchTypeList = reactive(codeList)
    return {
      searchTypeList
    }
  },
  data() {
    return {
      openYn: false,
      headerTitle: '최신순'
    }
  },
  watch: {},
  mounted() {
    if (!isEmpty(this.srchType)) {
      const srchTypeObj = find(this.searchTypeList, { note: this.srchType })
      this.headerTitle = isEmpty(srchTypeObj) ? '최신순' : srchTypeObj.header
    } else {
      this.headerTitle = isEmpty(this.searchTypeList) ? '최신순' : this.searchTypeList[0].header
    }
  },
  methods: {
    open() {
      this.openYn = true
    },
    close() {
      this.openYn = false
    },
    selectedItem(item) {
      this.headerTitle = item.header
      this.openYn = false
      setTimeout(() => {
        this.$emit('selected-item', item)
      }, 100)
    },
    searchPost() {
      this.$router.push({ path: '/CO/CO0101M05' })
    }
  }
}
</script>
